<template>
  <div class="d-flex flex-column">
    <div class="chooseBlock__logoCompany"></div>
    <div class="chooseBlock__shortText">Онлайн-сервис для быстрого поиска предмета лизинга</div>
    <div class="mt-6 text-h6">Если Вы хотите стать партнером онлайн-сервиса ПоискПЛ заполните форму ниже
      Мы свяжемся с Вами</div>
    <div>
      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-row class="mt-4">
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              label="Укажите Ваши ФИО"
              outlined
              v-model="name"
              hide-details="auto"
              :rules="nameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              label="Укажите Ваш контактный номер телефона"
              v-model="phone"
              :rules="[v => !!v || 'Обязательное поле']"
              outlined
              hide-details="auto"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              label="Укажите Ваш адрес корпоративной электронной почты"
              v-model="email"
              :rules="emailFieldRules"
              outlined
              hide-details="auto"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              label="Укажите наименование компании"
              v-model="company"
              :rules="[v => !!v || 'Обязательное поле']"
              outlined
              hide-details="auto"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <div style="width:375px; margin: 0 auto">
          <div class="text-caption text-center">
            Нажимая на кнопку “Отправить”, <a href="/legal/rules_for_using" target="_blank">Вы соглашаетесь <br/>
            на обработку своих персональных данных</a>
          </div>
          <div class="mt-6 text-center">
            <v-btn height="56" elevation="0" color="primary" block small   :disabled="!formValid" @click="sendMessage">Отправить</v-btn>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import TableMixin from '@/mixins/TableMixin.vue'
import SiteServiceApi from '@/apis/Site/ServiceApi'
// import RezervLcModal from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/RezervLсModal.vue'
export default {
  mixins: [TableMixin],
  components: {
    // RezervLcModal
  },
  data() {
    return {
      formValid: false,
      name: null,
      phone: null,
      email: null,
      company: null,
      nameRules: [
        v => !!v || 'Обязательное поле'
      ],
      emailFieldRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\..+/.test(v) || 'E-mail не корректный'
      ]
    }
  },

  watch: {},
  // created() {},

  methods: {
    sendMessage() {
      if (this.validate) {
        this.$setLoading(true)
        const params = {
          name: this.name,
          phone: this.phone,
          email: this.email,
          company: this.company
        }
        SiteServiceApi.sendRegisterEmail(params)
          .then(async ({ data }) => {
            this.name = this.phone = this.email = this.company = null
            this.showSuccessSystemNotification('Ваше сообщение успешно отправлено')
          })
          .finally(() => {
            this.$setLoading(false)
          })
      }
    },
    validate () {
      this.$refs.form.validate()
    }
  }
}
</script>
